const gateway = `https://api-gateway-dev.way2vat.com`;
const core = `https://api-gateway-dev.way2vat.com`;
const allServices = "https://dev-w2v.way2vat.com";

export const environment = {
  name: "development",
  production: false,
  qa: true,
  dev: false,
  gw: gateway,
  // token: `apiKey 2NTPJ33u0xGywJ8l2SOSGn:21eHPOSOTFFaHKo1CKsGxY`,
  gateway: `${gateway}/w2v`,
  configToken: `apiKey 0W9MPTmJwJTFUxs7F2fjU5:5tyZD4m1trcoryox20yI5v`,
  configuration: `${gateway}/w2v/configuration/configurations`,
  transformer: `${gateway}/w2v/transformer`,
  extractor: `${gateway}/w2v/extractor`,
  storm: `${gateway}/storm`,
  wv: `${gateway}/wolverine`,
  wvgql: `${gateway}/wolverine/graphql`,
  apai: `${gateway}/apai-web`,
  apaigql: `${gateway}/apai-web/graphql`,
  phoenixGql: `${gateway}/phoenix/graphql`,
  authGql: `${gateway}/phoenix/auth`,
  exporter: `${gateway}/w2v/exporter`,
  transformerQl: `${gateway}/w2v/transformer/ql`,
  connector: `${gateway}/api-connector`,
  core: `${core}/core/ql`,
  api: `${core}/core/api`,
  auth: `${core}/auth`,
  ms: `${core}/w2v/vat-calculator`,
  media: `${core}/core/media-service`,
  newMedia: `${gateway}/w2v/media/v2/media`,
  request: `${core}/core/api/connections/request`,
  download: `${gateway}/api/media/download`,
  analyticsTrackingCode: "UA-89763800-5",
  apaiDownload: `${gateway}/api/media/download/apai`,
  // graphQlLinkUri: `http://172.19.1.62:8080`
};
