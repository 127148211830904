import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { SettingsComponent } from "./views/settings/settings.component";
import { AuthGuardService } from "./services/auth-guard.service";
import { ExternalUrlService } from "./services/external-url.service";
import { ModalComponent } from "./components/modal/modal.component";
import { NgxPermissionsGuard } from "ngx-permissions";
import { UIPermissions } from "src/auth/permissions/permissions";
// import { ApaiUserStatusResolver } from "./modules/expenses/resolvers/apai-user-status.resolver";

const routes: Routes = [
  { path: "", redirectTo: "app", pathMatch: "full" },
  { path: "modal", component: ModalComponent },
  {
    path: "auth",
    loadChildren: () => import("../auth/auth.module").then((m) => m.AuthModule),
  },
  {
    path: "app",
    // resolve: { enableApai: ApaiUserStatusResolver },
    canActivate: [AuthGuardService],
    children: [
      //, canActivate: [AuthGuardService]
      { path: "", redirectTo: "groups", pathMatch: "full" },
      {
        path: "claims",
        canActivate: [NgxPermissionsGuard],
        data: { permissions: { only: UIPermissions.SHOW_CLAIMS.toString() } },
        loadChildren: () =>
          import("./modules/claims/claims.module").then((m) => m.ClaimsModule),
      },
      {
        path: "download",
        // canActivate: [NgxPermissionsGuard],
        loadChildren: () =>
          import("./modules/download/download.module").then(
            (m) => m.DownloadModule
          ),
      },
      // {
      //   path: "processes",
      //   canActivate: [NgxPermissionsGuard],
      //   data: {
      //     permissions: { only: UIPermissions.SHOW_DATA_UPLAOD.toString() },
      //   },
      //   loadChildren: () =>
      //     import("./modules/main/main.module").then((m) => m.MainModule),
      // },
      {
        path: "groups",
        canActivate: [NgxPermissionsGuard],
        data: { permissions: { only: UIPermissions.SHOW_ENTITIES.toString() } },
        loadChildren: () =>
          import("./modules/groups/groups.module").then((m) => m.GroupsModule),
      },
      {
        path: "users",
        canActivate: [NgxPermissionsGuard],
        data: { permissions: { only: UIPermissions.SHOW_USERS.toString() } },
        loadChildren: () =>
          import("./modules/users/users.module").then((m) => m.UsersModule),
      },
      {
        path: "integration",
        loadChildren: () =>
          import("./modules/integration/integration.module").then(
            (m) => m.IntegrationModule
          ),
      },
      {
        path: "dashboard",
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: { only: UIPermissions.SHOW_DASHBOARD.toString() },
        },
        loadChildren: () =>
          import("./modules/dashboard/dashboard.module").then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: "fees-configuration",
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: UIPermissions.SHOW_FEES_CONFIGURATION.toString(),
          },
        },
        loadChildren: () =>
          import("./modules/fees-configuration/fees-configuration.module").then(
            (m) => m.FeesConfigurationModule
          ),
      },
      {
        path: "apai",
        // canActivate: [NgxPermissionsGuard],
        // data: {
        //   permissions: {
        //     only: UIPermissions.SHOW_APAI.toString(),
        //   },
        // },
        loadChildren: () =>
          import("./modules/apai/apai.module").then((m) => m.ApaiModule),
      },
      {
        path: "data-management",

        loadChildren: () =>
          import("./modules/data-management/data-management.module").then(
            (m) => m.DataManagementModule
          ),
      },
      {
        path: "expenses",
        canActivate: [NgxPermissionsGuard],
        data: { permissions: { only: UIPermissions.SHOW_INVOICES.toString() } },
        loadChildren: () =>
          import("./modules/expenses/expenses.module").then(
            (m) => m.ExpensesModule
          ),
      },
      {
        path: "image-to-line",
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: { only: UIPermissions.SHOW_IMAGE_TO_LINE.toString() },
        },
        loadChildren: () =>
          import("./modules/image-to-line/image-to-line.module").then(
            (m) => m.ImageToLineModule
          ),
      },
      {
        path: "demo",

        loadChildren: () =>
          import("./modules/demo/demo.module").then((m) => m.DemoModule),
      },
      // { path: 'suppliers', loadChildren: () => import('./modules/suppliers/suppliers.module').then(m => m.SuppliersModule)},
    ],
  },
  {
    path: "externalRedirect",
    canActivate: [ExternalUrlService],
    children: [],
    // component: SettingsComponent
  },
  { path: "settings", component: SettingsComponent },
  {
    path: "imageToLine",
    loadChildren: () =>
      import("./modules/image-to-line/image-to-line.module").then(
        (m) => m.ImageToLineModule
      ),
  },
  {
    path: "**",
    redirectTo: "app",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: "reload" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
